<template>
  <div>
    <resource
      class="product-resource"
      :can-add="false"
      :can-delete="can('client.product.all')"
      canDeleteResourceKey="canBeDeleted"
      :show-speed-dial="true"
      :form-component="() => import('@/components/forms/ProductForm.vue')"
      :index-request="indexHandler"
      :meta="{name: $tc('product.title', 1), namePlural: $tc('product.title', 2)}"
      :model-type="modelType"
      :show-request="showHandler"
      :table-content="tableContent"
      :update-request="updateHandler"
      :delete-request="deleteHandler"
      :can-update="can('client.product.all')"
      @row-click="openProduct">
      <template #speedDialAfter>
        <v-tooltip left v-if="can('client.product.all')">
          <template #activator="{ on }">
            <v-btn @click="dialog = true" color="orange" dark fab v-on="on" small>
              <v-icon>fa-upload</v-icon>
            </v-btn>
          </template>

          <span>Producten uploaden</span>
        </v-tooltip>
      </template>
    </resource>
    <ProductsExportDialog v-model="dialog" :client="client"/>
  </div>
</template>

<script lang="js">
import { index, show, remove, update } from '@/api/endpoints/product.js';
import Product from '@/application/models/product.js';
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import ProductsExportDialog from '@/components/product/ProductsExportDialog.vue';
import { mapGetters } from "vuex";

export default {
  name: 'ProductResource',
  components: {
    ProductsExportDialog,
    Resource,
  },
  props: {
    client: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters("profile", ["can"]),
    showHandler: () => show,
    updateHandler: () => update,
    deleteHandler: () => remove,
    modelType: () => Product,
    tableContent() {
      return [
        {
          text: this.$t('product.fields.articleNumber'),
          sortable: true,
          value: 'articleNumber',
        },
        {
          text: this.$t('product.fields.ean'),
          sortable: true,
          value: 'ean',
        },
        {
          text: this.$t('product.fields.name'),
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('product.fields.stock'),
          sortable: true,
          value: 'stock',
        },
        {
          text: this.$t('product.fields.isActive'),
          sortable: true,
          value: 'isActive',
          columnType: 'checkbox',
        },
        {
          text: this.$t('product.fields.hasProductActions'),
          sortable: true,
          value: 'hasProductActions',
          columnType: 'checkbox',
        },
        {
          text: this.$t('product.fields.productType'),
          sortable: true,
          value: 'productType',
        },
      ];
    },
  },
  data() {
    return { dialog: false };
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'client.index' },
        text: this.$tc('client.title', 2),
      },
      {
        exact: true,
        to: { name: 'client.connectors' },
        text: this.client.name,
      },
      {
        exact: true,
        to: { name: 'client.products' },
        text: this.$tc('product.title', 2),
      },
    ]);
  },
  methods: {
    indexHandler() {
      return index(this.$route.params.clientId, ...arguments);
    },
    openProduct(productId) {
      if(!this.can("client.product.product-action.read")) {
        return;
      }
      this.$router.push({
        name: 'product.actions',
        params: { productId },
      });
    },
  },
};
</script>
