<template>
  <v-dialog v-model="dialog" width="600">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          {{$t('productAction.export.steps.download.title')}}
        </v-stepper-step>

        <v-stepper-step :complete="step > 2" step="2">
          {{$t('productAction.export.steps.upload.title')}}
        </v-stepper-step>

        <v-stepper-step step="3">
          {{$t('productAction.export.steps.finished.title')}}
        </v-stepper-step>
      </v-stepper-header>

      <k-field-group language-prefix="productAction.export">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-sheet height="200">
              <p>{{$t('productAction.export.steps.download.description')}}</p>
              <a @click="handleDownload" class="mr-3">{{$t('productAction.export.steps.download.link')}}</a>
              <v-progress-circular :indeterminate="isDownloading" color="primary" v-show="isDownloading" size="20"/>
            </v-sheet>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-sheet height="200">
              <v-form ref="form" v-model="valid">
                <p>{{$t('productAction.export.steps.upload.description')}}</p>
                <KFileField field="fields.file" required v-model="file" validation-field="file"/>
              </v-form>
            </v-sheet>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-sheet height="200">
              <p v-if="!uploadRequest.isRejected">{{$t('productAction.export.steps.finished.description')}}</p>
              <v-alert type="error" v-else>{{$t('productAction.export.steps.finished.errorDescription')}}</v-alert>
            </v-sheet>
          </v-stepper-content>
        </v-stepper-items>
      </k-field-group>

      <v-row class="ma-0 pa-3" justify="end" v-if="canContinue">
        <v-btn text color="grey" @click="back" :disabled="!canReturn">{{$t('actions.back')}}</v-btn>
        <v-btn color="primary" @click="next" :loading="uploadRequest.isPending">{{$t('actions.next')}}</v-btn>
      </v-row>
      <v-row class="ma-0 pa-3" justify="end" v-else>
        <v-btn color="primary" @click="dialog = false">{{$t('actions.finished')}}</v-btn>
      </v-row>
    </v-stepper>
  </v-dialog>
</template>

<script>
import KFileField from '@/components/crud/fields/KFileField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import { downloadCsv, uploadCsv } from '@/api/endpoints/productProductAction.js';

export default {
  name: 'ProductsExportDialog',
  components: {
    KFieldGroup,
    KFileField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      valid: false,
      dialog: this.value,
      isDownloading: false,
      file: null,
      uploadRequest: {
        isPending: false,
        isRejected: false,
      }
    };
  },
  computed: {
    canContinue() {
      return this.step < 3;
    },
    canReturn() {
      return this.step > 1;
    },
  },
  methods: {
    back() {
      if (!this.canReturn) return;

      this.step--;
    },
    async next() {
      if (!this.canContinue) return;
      if (this.step === 2) {
        this.$refs.form.validate();

        if (!this.valid) {
          return;
        } else {
          await this.handleUpload();

          if (!this.valid) return;
        }
      }

      this.step++;
    },
    async handleDownload() {
      if (this.isDownloading) return;

      this.isDownloading = true;
      let response = await downloadCsv(this.client.id);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = window.document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', 'Template.csv');
      window.document.body.appendChild(link);
      link.click();

      this.isDownloading = false;
    },
    async handleUpload() {
      if (this.uploadRequest.isPending) return;

      this.uploadRequest.isRejected = false;
      this.uploadRequest.isPending = true;

      return await uploadCsv(this.client.id, this.file)
        .catch(() => this.uploadRequest.isRejected = true)
        .finally(() => this.uploadRequest.isPending = false);
    },
    handleReset() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.step = 1;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.handleReset();
        this.dialog = this.value;
      },
    },
    dialog() {
      if (!this.dialog) this.$emit('input', false);
    },
  },
};
</script>
